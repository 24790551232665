import {
  collection,

  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import ListingItem from "../components/ListingItem";
import Slidera from "../components/Slidera";
import { db } from "../firebase";


export default function Offers() {
    // Offers
    const [offerListings, setOfferListings] = useState(null);
    useEffect(() => {
      async function fetchListings() {
        try {
          // get reference
          const listingsRef = collection(db, "listings");
          // create the query
          const q = query(
            listingsRef,
            where("open", "==", true), where ("recommend", "==", true),where ("marketu", "==", true),
            where ("vshort", "==", true),
            orderBy("timestamp", "desc"),
            limit(16)
          );
          // execute the query
          const querySnap = await getDocs(q);
          const listings = [];
          querySnap.forEach((doc) => {
            return listings.push({
              id: doc.id,
              data: doc.data(),
            });
          });
          setOfferListings(listings);
        } catch (error) {
          console.log(error);
        }
      }
      fetchListings();
    }, []);
    // Places for rent
    const [rentListings, setRentListings] = useState(null);
    useEffect(() => {
      async function fetchListings() {
        try {
          // get reference
          const listingsRef = collection(db, "listings");
          // create the query
          const q = query(
            listingsRef,
            where("open", "==", true), where ("recommend", "==", true),where ("marketu", "==", true),
            where ("short", "==", true),
            orderBy("timestamp", "desc"),
            limit(16)
          );
          // execute the query
          const querySnap = await getDocs(q);
          const listings = [];
          querySnap.forEach((doc) => {
            return listings.push({
              id: doc.id,
              data: doc.data(),
            });
          });
          setRentListings(listings);
        } catch (error) {
          console.log(error);
        }
      }
      fetchListings();
    }, []);
    // Places for rent
    const [saleListings, setSaleListings] = useState(null);
    useEffect(() => {
      async function fetchListings() {
        try {
          // get reference
          const listingsRef = collection(db, "listings");
          // create the query
          const q = query(
            listingsRef,
            where("open", "==", true), where ("recommend", "==", true),where ("marketu", "==", true),
            where ("mid", "==", true),
            orderBy("timestamp", "desc"),
            limit(16)
          );
          // execute the query
          const querySnap = await getDocs(q);
          const listings = [];
          querySnap.forEach((doc) => {
            return listings.push({
              id: doc.id,
              data: doc.data(),
            });
          });
          setSaleListings(listings);
        } catch (error) {
          console.log(error);
        }
      }
      fetchListings();
    }, []);
    return (
      <div>
        <Slidera />
        <div className="max-w-6xl mx-auto pt-4 space-y-6">
          {offerListings && offerListings.length > 0 && (
            <div className="m-2 mb-6">
              <h2 className="px-3 text-2xl mt-6 font-semibold">التداول اليومي</h2>
       
              <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
                {offerListings.map((listing) => (
                  <ListingItem
                    key={listing.id}
                    listing={listing.data}
                    id={listing.id}
                  />
                ))}
              </ul>
            </div>
          )}
          {rentListings && rentListings.length > 0 && (
            <div className="m-2 mb-6">
              <h2 className="px-3 text-2xl mt-6 font-semibold">
              التداول علي المدي القصير
              </h2>
  
              <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
                {rentListings.map((listing) => (
                  <ListingItem
                    key={listing.id}
                    listing={listing.data}
                    id={listing.id}
                  />
                ))}
              </ul>
            </div>
          )}
          {saleListings && saleListings.length > 0 && (
            <div className="m-2 mb-6">
              <h2 className="px-3 text-2xl mt-6 font-semibold">
              التداول علي المدي المتوسط
              </h2>
  
              <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
                {saleListings.map((listing) => (
                  <ListingItem
                    key={listing.id}
                    listing={listing.data}
                    id={listing.id}
                  />
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
  
import React from "react";
import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./FooterStyles";
 
const Footer = () => {
    return (
        <Box>
            <h1
                style={{
                    color: "white",
                    textAlign: "center",
                    marginTop: "10px",
                }}
            >
تم إعداد هذا التقرير من قبل شركة تريده فاينانشال للاستشارات وفقا للبيانات المتاحة للجميع. وقد بُنيت وجهة النظر في هذا التقرير وفقًا لهذه البيانات بمصداقية وأساليب علمية ودقيقة نعتقد في صحتها، وهي تقرير استرشادية غير ملزمة وان ما تنتهي اليه تلك التقارير من نتائج وتوصيات هي محض توصيات ونتائج إجتهادية تحتمل الصواب والخطأ وتحتفظ الشركة بكافة حقوقها الادبية والمعنوية علي البيانات والتحليلات .ويجوز الاطلاع علي هذا التقرير ولا يجوز نسخة او توزيعه او اذاعته او إعادة صياغته أو الاشتقاق منه أو القيام باي تصرف دون الاشارة الي الشركة والحصول علي موافقة كتابية من الشركة . كما تحتفظ الشركة بحقها في تعديل اي مما ورد بهذا التقرير دون الاعلان عن ذلك  والشركة غير مسؤولة عن أي قرارات استثمارية أو مضاربية يتم اتخاذها بناءً على المعلومات الواردة في هذا التقرير  .

            </h1>

            <h1
                style={{
                    color: "white",
                    textAlign: "center",
                    marginTop: "10px",
                }}
            >
 CETA, CFTe, FMVA®,CBCA®السيد الكحكي 

            </h1>
            <h1
                style={{
                    color: "white",
                    textAlign: "center",
                    marginTop: "10px",
                }}
            >
محلل مالي وفني 
            </h1>
            <h1
                style={{
                    color: "white",
                    textAlign: "center",
                    marginTop: "10px",
                }}
            >
منصة تريده منصة ذكية تديرها و تطورها شركة تريده فاينانشال للاستشارات

            </h1>
          
        </Box>
    );
};
export default Footer;